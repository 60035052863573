import http from "./http";
import qs from "qs";

export default {
  Login(data) {
    return http.post("https://satisfying-bouquet-74b514aeab.strapiapp.com/api/auth/local", qs.stringify(data));
  },

  Chaxun() {
    return http.get("https://satisfying-bouquet-74b514aeab.strapiapp.com/api/users");
  },

  UserChaxun() {
    return http.get("https://satisfying-bouquet-74b514aeab.strapiapp.com/api/users-permissions/roles");
  },

  WenzhangChaxun() {
    return http.get("https://satisfying-bouquet-74b514aeab.strapiapp.com/api/articles");
  },


  Chuangjian(data) {
    return http.post("https://satisfying-bouquet-74b514aeab.strapiapp.com/api/auth/local/register",qs.stringify(data));
  },

  findCountryCode(data) {
    return http.get("/client/common/findCountryCode", { params: data });
  },
  getVerify(data) {
    return http.get("/client/common/common/getVerify", data);
  },
  //修改密码
  upPassWord(data) {
    return http.post("/client/user/updatePassWord", qs.stringify(data));
  },
  //找回密码 （邮箱的）
  // retrievePassWord(data) {
  //   return http.post("/client/user/forgetPassWord", qs.stringify(data));
  // },

  //找回密码 （手机的）
  retrievePassWord(data) {
    return http.post("/client/user/forgetPassWordByPhone", qs.stringify(data));
  },
  //获取用户信息
  getUserInfo(data) {
    return http.post("/client/user/getUserInfo", qs.stringify(data));
  },
  //获取电子游戏列表
  getElectronicGameInfoList(data) {
    return http.get("/client/gameInfo/getElectronicGameInfoList", data);
  },

  //获取游戏厂商列表
  getGameTypeList(data) {
    return http.get("/client/gameType/getGameTypeList", data);
  },

  //获取常用问题列表
  getQuestions(data) {
    return http.get("/client/question/getQuestions", data);
  },

  //获取轮播/公告列表
  getBannerList(data) {
    return http.get("/client/banner/getBannerList", data);
  },

  //打开游戏
  openGameInfo(data) {
    return http.get("/client/gameInfo/openGameInfo", data);
  },

  //打开试玩游戏
  openGameTestInfo(data) {
    return http.get("/client//gameInfo/openTestGameInfo", data);
  },

  //获取活动列表
  getActivitys(data) {
    return http.get("/client/activity/getActivitys", data);
  },

  //产品列表
  getProductList(data) {
    return http.get("/client/public/findCurrencyProductList", data);
  },

  //登出
  logout(data) {
    return http.post("/client/user/logout", data);
  },

  //获取单个活动详情
  getActivityDetails(data) {
    return http.get("/client/activity/getActivity", data);
  },

  //获取公司钱包列表
  getWalletList(data) {
    return http.post("/client/wallet/getList", qs.stringify(data));
  },

  //获取用户账户集合（首页）
  getUserAccountList(data) {
    return http.post("/client/userMoney/getUserMoneyList", qs.stringify(data));
  },

  // 获取用户钱包列表
  userWalletList(data) {
    return http.post("/client/userWallet/getList", qs.stringify(data));
  },

  // 用户充值
  insertUserRecharge(data) {
    return http.post(
      "/client/userMoney/insertUserRecharge",
      qs.stringify(data)
    );
  },

  // 获取收藏游戏数
  getCollectionGameCount(data) {
    return http.get("/client/userCollectionGames/getCollectionGameCount");
  },

  // 获取未读消息数
  getMessageCount(data) {
    return http.get("/client/systemMessage/getMessageCount");
  },

  // 获取用户收藏游戏列表
  getUserCollectionGamesList(data) {
    return http.get("/client/userCollectionGames/getUserCollectionGamesList", {
      ...data,
    });
  },

  // 获取用户收藏游戏列表
  collectionGame(data) {
    return http.get("/client/userCollectionGames/collectionGame", { ...data });
  },

  // 获取消息中心列表
  getSystemMessageList(data) {
    return http.get("/client/systemMessage/getSystemMessageList", { ...data });
  },

  // 获取消息中心列表(分页)
  getSystemMessagePageInfo(data) {
    return http.get("/client/systemMessage/getSystemMessagePageInfo", {
      ...data,
    });
  },

  // 已读消息
  readMessage(data) {
    return http.get("/client/systemMessage/readMessage", { ...data });
  },

  // 获取币种
  getCurrency(data) {
    return http.post("/client/userMoney/getCurrency");
  },

  // 获取网络
  getChain(data) {
    return http.post("/client/userMoney/getChain", qs.stringify(data));
  },

  // 获取充值地址
  getRechargeAddress(data) {
    return http.post(
      "/client/userMoney/getRechargeAddress",
      qs.stringify(data)
    );
  },
  // 用户提现
  insertUserWithdraw(data) {
    return http.post(
      "/client/userMoney/insertUserWithdraw",
      qs.stringify(data)
    );
  },

  // 获取用户下级列表
  getUserDownList(data) {
    return http.post("/client/user/getUserDownList", qs.stringify(data));
  },

  // 文件上传
  uploadFileImg(fileImg) {
    const formData = new FormData();
    formData.append("fileImg", fileImg);
    return http.post("/client/common/common/uploadFileImg", formData);
  },

  // 修改用户头像
  heardImg(data) {
    return http.post("/client/user/heardImg", qs.stringify(data));
  },

  // 修改用户昵称
  userNickName(data) {
    return http.post("/client/user/userNickName", qs.stringify(data));
  },

  // 收藏或取消收藏游戏
  collectGame(data) {
    return http.get("/client/userCollectionGames/collectionGame", data);
  },

  // 产品的历史期数-分页
  getProductHistory(data) {
    return http.get("/client/periods/findH5ProductPeriodsPage", data);
  },

  // 文件上传
  upload(fileImg) {
    const formData = new FormData();
    formData.append("file", fileImg);
    return http.post("/client/public/upload", formData);
  },

  // 获取阿里云oos域名
  getOosUrl(data) {
    return http.post("/client/public/getOosUrl", data);
  },

  // 获取用户vip信息
  getUserVipInfo(data) {
    return http.get("/client/vip/getUserVipInfo", data);
  },

  getUserOrderList(data) {
    return http.get('/client/periodsOrder/findUserOrderList', data)
  },

  // 查询注册协议列表
  getRegistrationAgreementList(data) {
    return http.post('/client/registrationAgreement/getList', data)
  },
  // 取用户充值记录列表
  getRechargePageInfo(data) {
    return http.get('/client/userRecharge/getPageInfo', data)
  },
  // 提现记录
  getWithdrawPageInfo(data) {
    return http.get('/client/userWithdraw/getPageInfo', data)
  },
  // 个人投资记录
  getMeGameInfoList(data) {
    return http.get('/client/gameBetHistory/getMeGameInfoList', data)
  },
  getGameHistoryList(data) {
    return http.get('/client/gameBetHistory/getGameHistoryList', data)
  },

  //获取哈希配置类型
  getHashConfigType(data) {
    return http.get('/client/configType/findByConfigTypeList', data)
  },

  //获取用户vip领奖信息
  getUserVipBonusInfo(data) {
    return http.get('/client/vip/getUserVipBonusInfo', data)
  },

  //hash 下注订单
  insertPeriodsOrder(data) {
    return http.post('/client/periodsOrder/insertPeriodsOrder', qs.stringify(data))
  },

  //获取收益排名
  getRankingData(data) {
    return http.get('/client/gameBetHistory/selectRanking', data)
  },
  //获取个人投注记录列表
  getMeBetRecord(data) {
    return http.get('/client/gameBetHistory/getMeGameInfoList', data)
  },

  //获取客服信息
  getCustomerService(data) {
    // return http.get('/client/online/getOnline', data)
    return http.post('/client/config/CUSTOMER_SERVICE_LINK', qs.stringify(data))
  },
  // home页签
  homeTabsList(data) {
    return http.get('/client/homeTab/getHomeTabList', data)
  },
  getGameInfo(data) {
    return http.get('/client/gameInfo/getGameInfo', data)
  },
  checkIsLogin(data) {
    return http.post('/client/user/checkLogin', data)
  },

  //哈希投注获取最大与最低投注限制
  getBetLimit(data) {
    return http.get('/client/currencyMoney/findCurrencyMoneyList', data)
  },
  //领取用户 反水 奖金
  receiveRebate(data) {
    return http.get('/client/vip/receiveUserBackBonus', data)
  },
  //领取用户 退水 奖金
  receiveBack(data) {
    return http.get('/client/vip/receiveUserRetreatBonus', data)
  },
  //领取用户vip 升级 奖金
  receiveUpgrade(data) {
    return http.get('/client/vip/receiveUserVipBonus', data)
  },

  updateInviteCode(data) {
    return http.post('/client/user/updateInviteCode', qs.stringify(data))
  },

  getSportUrl(data) {
    return http.get('/client/gameInfo/openObSportsGameInfo', data)
  },

  getLbcSportUrl(data) {
    return http.get('/client/gameInfo/openLbcSportsGameInfo', data)
  },

  getInviteData(data) {
    return http.post('/client/userInviteActivity/getDefaultSumData', qs.stringify(data))
  },

  getSportsBetHistory(data) {
    return http.get('/client/gameBetHistory/getSportsBetHistory', data)
  },

  //推广码
  senPCode(data) {
    return http.post('/client/promotion/addCNum', qs.stringify(data))
  },

  // 网站相关配置信息
  getWebConfig(data) {
    return http.get('/client/websiteConfig/getWebsiteConfig', data)
  },
  //metamask
  metamaskLogin(data) {
    return http.post('/client/user/metaMaskValidate', qs.stringify(data))
  },

  //用户google登录
  userGoogleLogin(data) {
    return http.post('/client/user/googleLogin', qs.stringify(data))
  },

  //用户apple登录
  appleVerifyToken(data) {
    return http.post('/client/user/appleLogin', qs.stringify(data))
  },

  //tg登录
  userTelegramLogin(data) {
    return http.post('/client/user/tgValidate', qs.stringify(data))
  },

  //修改或设置邮箱
  updateEmail(data) {
    return http.post('/client/user/updateEmail', qs.stringify(data))
  },


  //获取用户绑定钱包列表
  getUserBindWalletList(data) {
    return http.post('/client/virtualCoinWalletInfo/getList', qs.stringify(data))
  },

  //获取用户绑定指定币种钱包列表
  getUserSpecifyBindWalletList(data) {
    return http.post('/client/virtualCoinWalletInfo/getListByUserId', qs.stringify(data))
  },

  //删除用户绑定钱包
  deleteUserBindWallet(data) {
    return http.post('/client/virtualCoinWalletInfo/delete', qs.stringify(data))
  },

  //新增用户绑定钱包
  bindUserWallet(data) {
    return http.post('/client/virtualCoinWalletInfo/insert', qs.stringify(data))
  },

  //获取一级好友列表
  getLevelOneFriendList(data) {
    return http.post('/client/userInviteActivity/getDownUserData', qs.stringify(data))
  },

  //获取二级好友列表
  getLevelTwoFriendList(data) {
    return http.post('/client/userInviteActivity/getDownUserTwoData', qs.stringify(data))
  },
// 下级列表
  getDownUserPageInfo(data) {
    return http.post('/client/userInviteActivity/getDownUserPageInfo', qs.stringify(data))
  },

  //获取用户收益记录
  getAccountChangeList(data) {
    return http.get("/client/accountChange/getProfitPageInfo", data);
  },

  getUserAllBet(data) {
    return http.post("/client/user/getUserAllBet", data);
  },

  getUserAllWin(data) {
    return http.post("/client/user/getUserAllWin", data);
  },
  getUserAllRebate(data) {
    return http.post("/client/user/getUserAllRebate", data);
  },

  statisticsLink(data) {
    return http.post('/client/user/link/click', qs.stringify(data));
  },
  apiTokenLogin(data) {
    return http.post('/client/user/api/token', qs.stringify(data));
  },
//获取BTB可兑换金额
  getBtbCanMoney(data) {
    return http.get('/client/userBtbExchangeInfo/getBtbCanMoney', data);
  },
  //BTB兑换
  exchangeBtb(data) {
    return http.get('/client/userBtbExchangeInfo/exchangeBtb', data);
  },

  // 获取法币支付渠道
  getPayChannel(data) {
    return http.get('/client/ltpay/getPayChannel', data);
  },

  // 获取法币支付配置 金额等
  getPayConfigByChannel(data) {
    return http.get('/client/ltpay/getPayConfigByChannel', data);
  },

  // 获取法币首冲活动列表
  getFirstActivityList(data) {
    return http.post('/client/activity/list/rechargeFirstActivityInfo/getFirstActivityList', qs.stringify(data));
  },

  // 获取法币每日充值活动列表
  getEverydayActivityList(data) {
    return http.post('/client/activity/list/rechargeEverydayActivityInfo/getEverydayActivityList', qs.stringify(data));
  },

  // 获取法币用户可参与活动次数
  getUserParticipationTimes(data) {
    return http.post('/client/activity/user/getCanUserParticipationActivityNumber', qs.stringify(data));
  },

  // 法币充值
  currencyRecharge(data) {
    // return http.get('/client/recharge/dypay/createOrder', data); // old
    return http.get('/client/recharge/pay/createMkcpayOrder', data); // new 通用多渠道充值
  },

  // 获取用户充值中订单（存在则返回订单信息 不存在返回null）
  getRechargeIng(data) {
    return http.get('/client/userRecharge/getRechargeIng', data);
  },

  // 取消充值中订单
  cancelRechargeIng(data) {
    return http.get('/client/userRecharge/cancel', data);
  },

  // 获取当前系统货币类型
  getSystemCurrencyMoney(data) {
    return http.post('/client/currencyMoney/getSystemCurrencyMoney', qs.stringify(data));
  },

  // 查询用户VIP等级信息  new
  getVipUserLevel(data) {
    return http.get('/client/vipInfo/getUserInfo', data);
  },

  // 查询用户VIP 金额奖励等信息  new
  getVipLevelAmt(data) {
    return http.get('/client/vipInfo/getUserStatistics', data);
  },

  //获取vip列表 List
  getVipInfoList(data) {
    return http.get('/client/vipInfo/getVipList', data)
  },

  // 领取VIP奖励
  receiveRewards(data) {
    return http.post('/client/vipInfo/receive', qs.stringify(data));
  },

  // 团队首页统计
  teamPageAccount(data) {
    return http.post('/client/team/teamPageAccount', qs.stringify(data));
  },

  // 团队详情统计
  teamDetailPageAccount(data) {
    return http.post('/client/team/teamDetailPageAccount', qs.stringify(data));
  },

  // 发放奖励 团队领取
  getTeamAward(data) {
    return http.post('/client/team/sendAward', qs.stringify(data));
  },

  //获取用户所有账变列表
  getAmountChange(data) {
    return http.get('/client/accountChange/getPageInfo', data)
  },

  // 获取首页弹窗列表
  mobileHomePopup(data) {
    return http.post('/client/mobileHomePopup', qs.stringify(data));
  },
  //查询用户提现账户信息
  findUserWithdrawalInfo(data){
    return http.get('/client/userWithdrawalInfo/findUserWithdrawalInfo', data)
  },
  //获取用户可提现金额
  getCanUserWithdrawMoney(data) {
    return http.post('/client/userMoney/getCanUserWithdrawMoney', qs.stringify(data));
  },
  //新增或修改用户提现账户信息
  inserOrUpdate(data) {
    return http.post('/client/userWithdrawalInfo/inserOrUpdate', qs.stringify(data));
  },

  //查询币种相关信息，提现限制等（单个）
  getCurrencyMoneyConfig(data) {
    return http.post('/client/currencyMoney/getCurrencyMoney', qs.stringify(data));
  },

  //用户法币提现
  userWithdrawalLegal(data) {
    return http.post('/client/userMoney/userWithdrawalLegal', qs.stringify(data));
  },
  //获取修改手机号次数
  getUpdatePhoneCount(data) {
    return http.post('/client/user/getUpdatePhoneCount', qs.stringify(data));
  },
  //修改或设置手机号
  updatePhone(data) {
    return http.post('/client/user/updatePhone', qs.stringify(data));
  },
  //账号模式设置手机 (无需验证)
  updatePhoneNoCheck(data) {
    return http.post('/client/user/updatePhoneNoCheck', qs.stringify(data));
  },
  //提现账户修改验证码校验并返回未加密的账户信息
  checkTxCode(data) {
    return http.post('/client/user/checkTxCode', qs.stringify(data));
  },
  //是否开启人机验证（1=开启 2=关闭）
  manMachineSwitch(data) {
    return http.post('/client/config/IS_OPEN_MAN_MACHINE_VERIFICATION', qs.stringify(data));
  },

  // 是否开启数字货币充值（1=开启 2=关闭）
  currencyRechargeSwitch(data) {
    return http.post('/client/config/IS_OPEN_DIGITAL_CURRENCY_RECHARGE', qs.stringify(data));
  },

  // 是否开启数字货币提现（1=开启 2=关闭）
  currencyWithdrawSwitch(data) {
    return http.post('/client/config/IS_OPEN_DIGITAL_CURRENCY_WITHDRAWAL', qs.stringify(data));
  },

  // 系统单个返回参数  key参数获取
  findConfigByKey(data) {
    return http.get('/client/public/findConfigByKey', data)
  },

  // 获取luckWin 脸书，tg等跳转配置页面图标配置
  getExternalLinkList(data) {
    return http.post('/client/luckwinPicConfig/getShowList', qs.stringify(data));
  },

  getDownloadUrl(data){
    return http.get('/client/public/getVsersion', data);
  },

  // 新产品 新增需求 07-27 =============================================================

  // 查询是否强制填写邀请码（1=强制 2=不强制）
  mandatoryInviteCode(data) {
    return http.post('/client/config/WHETHER_THE_INVITATION_CODE_IS_MANDATORY', qs.stringify(data));
  },

  // 查询是否可以修改邀请码（1=可以 2=不行）
  modifyInviteCode(data) {
    return http.post('/client/config/IS_UPDATE_INVITATION_CODE', qs.stringify(data));
  },

  // 获取全局文案相关配置
  getGlobalTextList(data) {
    return http.get('/client/agreementContentInfo/getList', data);
  },

  // 获取菲律宾提现支付方式配置
  getPhilippinesPayType(data) {
    return http.get('/client/ltpay/getCurrencyWithdrawList', data);
  },

  // 获取菲律宾提现支付方式配置
  verifyWithdrawInfo(data) {
    return http.post('/client/userWithdrawalInfo/verify', qs.stringify(data));
  },

  // 综合前端使用获取pix code
  getPixCode(data){
    // 更改新的统计接口，
    // return http.post("/client/promotionPage/config", qs.stringify(data));
    return http.post("/client/promotionPage/indexConfig", qs.stringify(data));

  },


  // new add  提现相关通道模式的接口类型
  getWithdrawType(data){
    return http.get('/client/ltpay/getcurrentWithdrawPayType', data);

  }

};
