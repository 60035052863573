<template>
  <router-view />
</template>

<style lang="scss">
#app {
  // font-family: PingFangSC-Semibold, PingFang SC;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  width: 100%;
  /*min-height: 100vh;*/
  background: black;
  height: 100vh;
  overflow-y: auto;
}
</style>
