import { createStore } from 'vuex'

export default createStore({
  state: {
    currentGameUrl: '',
    currentGameId: 0,
    infoList: [
      {
        name: 'PP老虎机',
        info: '我们屡获殊荣的老虎机产品组合包含独特的内容，包括提供多种货币。多种语言。',
        img: require('../assets/imgs/game_list_icon_0.png')
      },
      {
        name: '原创游戏',
        info: '原创游戏',
        img: require('../assets/imgs/game_list_icon_0.png')
      }
    ],
    pgList: [
      {
        name: 'Sugar Rush',
        info: '',
        img: require('../assets/imgs/game_list_0.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=sugarrush'
      },
      {
        name: 'Sugar Rush 1000',
        info: '',
        img: require('../assets/imgs/game_list_1.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=sugarrush1000'
      },
      {
        name: 'Sugar Rush Xmas',
        info: '',
        img: require('../assets/imgs/game_list_2.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=sugarrushxmas'
      },
      {
        name: 'Starlight Princess',
        info: '',
        img: require('../assets/imgs/game_list_3.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=princess'
      },
      {
        name: 'Starlight Princess 1000',
        info: '',
        img: require('../assets/imgs/game_list_4.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=princess1000'
      },
      {
        name: 'Starlight Christmas',
        info: '',
        img: require('../assets/imgs/game_list_5.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=princessxmas'
      },
      {
        name: 'Gates of Olympus',
        info: '',
        img: require('../assets/imgs/game_list_6.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=ghtesofolympus'
      },
      {
        name: 'Gates of Olympus 1000',
        info: '',
        img: require('../assets/imgs/game_list_7.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=ghtesofolympus1000'
      },
      {
        name: 'Gates of Olympus Dice',
        info: '',
        img: require('../assets/imgs/game_list_8.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=ghtesofolympusdice'
      },
      {
        name: 'Sweet Bonanza',
        info: '',
        img: require('../assets/imgs/game_list_9.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=sweetbonanza'
      },
      {
        name: 'Sweet Bonanza 1000',
        info: '',
        img: require('../assets/imgs/game_list_10.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=sweetbonanzadice'
      },
      {
        name: 'Sweet Bonanza XMAS',
        info: '',
        img: require('../assets/imgs/game_list_11.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=sweetbonanzaxmas'
      },
      {
        name: 'The Dog House ',
        info: '',
        img: require('../assets/imgs/game_list_12.png'),
        url: 'https://m.pphey.cc/casino/mobile/index.html?name=thisdoghouse'
      }
    ],
    ogList: [
      {
        name: '',
        info: '',
        img: ''
      },
      {
        name: '',
        info: '',
        img: ''
      },
      {
        name: '',
        info: '',
        img: ''
      }
    ]
  },
  getters: {
  },
  mutations: {
    ['SET_DATA'](state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
