import md5 from 'md5'
export default function getToken(key, options) {
	options = options || {};	
	let epoch, time, shaObj, hmac, offset, otp;
	options.period = options.period || 30;
	options.algorithm = options.algorithm || 'SHA-1';
	options.digits = options.digits || 6;
	epoch = md5(Math.round(Date.now() / 1000/20)+key);

	return epoch;
}