import { createRouter ,createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'first',
    component: () =>
      import(/* webpackChunkName:"activityDetails"*/ '../views/first/first.vue'),
  },
  {
    path: '/game',
    name: 'game',
    component: () =>
      import(/* webpackChunkName:"activityDetails"*/ '../views/game/game.vue'),
  },
  {
    path: '/info',
    name: 'info',
    component: () =>
      import(/* webpackChunkName:"activityDetails"*/ '../views/info/info.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router
