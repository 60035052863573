import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import './common/font/font.css'

import gameUtils from 'awesome-game-utils'
import awesomeUi from "awesome-h5-ui";

import animate from 'animate.css'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css'

import apis from "./http/apis";

import './common/font/font.css'



gameUtils.rem(document, window, 750);
gameUtils.stopZoom();

const app = createApp(App);
router.afterEach(r => {
    window.scrollTo(0, 0)
})

let h = window.innerHeight;
let w = Math.floor(h / 2);

if (!gameUtils.adaptPC(w, h)) {
    app.config.globalProperties.$api = apis;
    app.config.globalProperties.$gameUtils = gameUtils;
    app.use(store).use(router).use(ElementPlus).use(animate).use(awesomeUi).mount("#app");
}

