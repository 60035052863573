import axios from "./axios";

// get 请求
function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params }).then((res) => {
            // console.log(res,'get');
            if (res) {
                resolve(res.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

// post请求
function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios({ url, method: 'post', data, }).then(res => {
            if (res) {
                resolve(res.data)
            }
            // resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}


const http = {
    get,
    post,
}
export default http;
