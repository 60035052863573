import axios from "axios";
import sign from './sign'



    axios.defaults.baseURL = '';


axios.defaults.timeout = 100 * 1000
axios.defaults.headers['content-type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Accept'] = "*/*";
axios.defaults.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
//axios.defaults.headers['pragram'] = "no-cache";
//axios.defaults.headers['expires'] = "0";
axios.interceptors.request.use(
    config => {
        const newSign = sign("JRPRIVATEKEY", { digits: 8, algorithm: "SHA-512", period: 20 });
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // if ((config.data && config.data.loading != false) ||
        //   config.params && config.params.loading != false) {
        //   Toast.loading({
        //     forbidClick: true,
        //     duration: 0
        //   });
        // }
        config.headers.sign = newSign.substr(0, 10)
        const token = localStorage.getItem('token');
        config.headers.authorization = token || "";
        config.headers.localtimes = Date.now(); // 当前时间搓

     
       

        config.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate'
        config.headers['pragram'] = 'no-cache'
        config.headers['expires'] = '0'

        // config.headers.userToken = token || "";
        return config;
    },
    error => {
        return Promise.error(error);
    })

axios.interceptors.response.use(
    response => {
        response.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate'
        // response.headers['pragram'] = 'no-cache'
        // response.headers['expires'] = '0'
      
        if (response.data.statusCode === 201) {
          
        } else if (response.data.statusCode === 202) {
          
        }
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            // console.log(router.currentRoute,router.currentRoute.value.path);
          
           
            // return Promise.resolve(response.data);
            return response
        } else {
         
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        // Toast.clear()
        let data = (error.response && error.response.data) ? error.response.data : null
        if (data) {
          

            switch (data.status) {
                case 401:
                  
                    // router.push('/login')
                    break;
                    // 跳转登录页面
                case 403:
                    break;
                    // 404请求不存在
                case 404:
                  
                    break;
                    // 其他错误，直接抛出错误提示
                default:
            }
            return Promise.reject(error.response);
        } else {
          
        }

    });

export default axios;
